.rwt__tabs[data-rwt-vertical="true"] {
    display: flex;
}

.rwt__tablist:not([aria-orientation="vertical"]) {
    border-bottom: 1px solid #ddd;
}

.rwt__tablist[aria-orientation="vertical"] {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    border-right: 1px solid #ddd;
    margin-right: 1rem;
}

.rwt__tab {
    background: transparent;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    padding: 1rem 1.5rem;
    transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
    outline: 0;
    background-color: #f4f4f4;
    background-color: rgba(0,0,0,0.05);
}

.rwt__tab[aria-selected="true"] {
    position: relative;
}

.rwt__tab[aria-selected="true"]:after {
    content: '';
    position: absolute;
}

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
    bottom: -1px;
    left: 0;
    width: 100%;
    border-bottom: 3px solid #00d8ff;
}

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: 3px solid #00d8ff;
}
.contentheader{
    padding-bottom: 0px !important;
    overflow-y: auto !important;
    background-color:#1E292F;
}
fieldset{
    border: 1px solid lightgray;
    margin-bottom:15px;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  color: #A8B5BD;
  margin-left: 5px;
}

.react-tabs__tab-list {
  padding: 0;
  border-bottom: 0.5px solid #2F404A;
  font-family: inherit;
  font-size: inherit;
}

.react-tabs__tab {
  display: inline-block;
  border-bottom: none;
  bottom: -1px;
  list-style: none;
  padding: 5px 10px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: transparent;
  color: #A8B5BD;
  border-bottom: 3px solid #A8B5BD;
  border-radius: 5px 5px 0 0;
  user-select: none;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  background:transparent;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}