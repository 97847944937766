
@media screen and (min-width: 1350px) {
    .deviceInfoOne {
        width: 48%;
    }
    
    .deviceInfoTwo {
        width: 48%;
    }
    .deviceInfoContainer {
        padding: 10px 2%;
        justify-content: space-between !important;
    }
}

@media screen and (max-width: 1350px) {
    .deviceInfoContainer {
        justify-content: space-between;
    }
    .deviceInfoOne {
        width: 500px;
    }
    .deviceInfoTwo {
        width: 500px;
    }
}

@media screen and (max-width: 1330px) {
    .deviceInfoOne {
        width: 475px;
    }
    .deviceInfoTwo {
        width: 475px;
    }
}

@media screen and (max-width: 1275px) {
    .deviceInfoOne {
        width: 450px;
    }
    .deviceInfoTwo {
        width: 450px;
    }
}

@media screen and (max-width: 1230px) {
    .deviceInfoOne {
        width: 415px;
    }
    .deviceInfoTwo {
        width: 415px;
    }
}

@media screen and (max-width: 1150px) {
    .deviceInfoContainer {
        justify-content: flex-start;
    }
    .deviceInfoOne {
        width: 525px;
    }
    .deviceInfoTwo {
        width: 525px;
    }
}

@media screen and (max-width: 900px) {
    .deviceInfoOne {
        width: 500px;
    }
    .deviceInfoTwo {
        width: 500px;
    }
}

@media screen and (max-width: 815px) {
    .deviceInfoOne {
        width: 90%;
    }
    .deviceInfoTwo {
        width: 90%;
    }
}

@media screen and (max-width: 775px) {
    .deviceInfoContainer {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 730px) {
    .deviceInfoContainer {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 680px) {
    .deviceInfoContainer {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 550px) {
    .deviceInfoContainer {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 450px) {
    .deviceInfoContainer {
        font-size: 0.7rem;
    }
}
